import image1 from "./../Assets/images/image1.jpeg"
import image2 from "./../Assets/images/image2.jpeg"
import image3 from "./../Assets/images/image3.jpeg"
import image4 from "./../Assets/images/image4.jpeg"
import image5 from "./../Assets/images/image5.jpeg"
import image6 from "./../Assets/images/image6.jpeg"
import image7 from "./../Assets/images/image7.jpeg"
import image8 from "./../Assets/images/image8.jpeg"
import image9 from "./../Assets/images/image9.jpeg"
import image10 from "./../Assets/images/image10.jpeg"
import image11 from "./../Assets/images/image11.jpeg"
import image12 from "./../Assets/images/image12.jpeg"
import image13 from "./../Assets/images/image13.jpeg"
import image14 from "./../Assets/images/image14.jpeg"



const listOfImages = [
    image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14
];

export default listOfImages;